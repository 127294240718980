<template>
  <section>
    <div class="content-header">
      <h2>Students’ sense of campus community: What it means, and what to do about it </h2>
    </div>
    <div class="content-wrapper">
      <p>The study, <a href="https://www.tandfonline.com/doi/abs/10.2202/1949-6605.1331" target="_blank">Students’ sense of campus community: What it means, and what to do about it</a> by David X. Cheng examines different aspects of students’ academic life to articulate their perceptions of campus community. </p>
      <p>The findings indicate that students’ sense of community is closely associated with their feelings of being cared about, treated in a caring way, valued as an individual and accepted as a part of a community and the quality social life on campus.</p>
      <p>The most negative influence on sense of community comes from students’ feelings of loneliness on campus.</p>
      <p>The authors propose five ways for schools to instill a sense of campus community:</p>
      <div class="carousel">
        <hooper
          ref="carousel_01"
          class="mb-4"
          :wheelControl="false"
        >
          <slide>
            <div class="slide">
              <p>1. Have an open environment where free expressions are encouraged, and individuality is accepted and respected</p>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <p>2. Engage faculty and students in teaching and learning</p>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <p>3. Provide an active social and learning environment in residence halls</p>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <p>4. Foster positive relationships among ethnic and cultural groups through programs and student activities</p>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <p>5. Celebrate traditions and heritage of the institution</p>
            </div>
          </slide>

          <slide>
            <div class="slide">
              <p>6. Provide assistance to students when they feel lonely or depressed</p>
            </div>
          </slide>

          <hooper-navigation slot="hooper-addons">
            <img
              slot="hooper-prev"
              src="@/assets/img/_ui/svg/carousel-back.svg"
              alt="previous"
              srcset=""
            >
            <img
              slot="hooper-next"
              src="@/assets/img/_ui/svg/carousel-next.svg"
              alt="next"
              srcset=""
            >
          </hooper-navigation>

          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </div>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation,
  Pagination as HooperPagination
} from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
